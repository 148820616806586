import { Box } from '@material-ui/core';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import routes from '../../api/routes';
import useFetch from '../../hooks/useFetch';
Chart.register(...registerables, ChartDataLabels);

const HourlyStats = ({
	missionId,
	recruiterId,
	runningMissionsOnly,
	lowerTimeLimit,
	upperTimeLimit,
}: {
	missionId?: string;
	recruiterId?: string;
	runningMissionsOnly?: boolean;
	lowerTimeLimit?: number;
	upperTimeLimit?: number;
}) => {
	let [hourlyStatsAreLoading, hourlyStats] = useFetch<Record<string, number>>(
		routes.donations.hourlyStatsForMission(
			{},
			{ missionId, recruiterId, runningMissionsOnly, lowerTimeLimit, upperTimeLimit },
		),
		[recruiterId, lowerTimeLimit, upperTimeLimit],
	);
	if (hourlyStatsAreLoading) return <Box>loading...</Box>;

	// let colors = ['#81C928', '#26C1C9', '#1A4145', '#FACA00', '#AB7DF6', '#FF7777'];
	let data = {
		labels: Object.keys(hourlyStats).map((hour) => hour + 'h'),
		datasets: [
			{
				data: Object.values(hourlyStats),
				backgroundColor: '#AB7DE666',
				hoverBackgroundColor: '#AB7DE6',
				maxBarThickness: 10,
			},
		],
	};

	let max = Math.round(Math.max(...Object.values(hourlyStats)) * 1.1 + 1);
	let min = Math.round(Math.min(...Object.values(hourlyStats)) * 0.5);

	return (
		<Bar
			data={data}
			width={400}
			height={300}
			options={{
				scales: {
					yAxis: {
						max,
						min,
						ticks: {
							display: false,
						},
						grid: {
							drawBorder: false,
							color: 'rgba(0, 0, 0, 0.05)',
						},
					},

					xAxis: {
						grid: {
							display: false,
							drawBorder: false,
							color: 'rgba(0, 0, 0, 0)',
						},
					},
				},
				plugins: {
					datalabels: {
						align: 'end',
						anchor: 'end',
						offset: 0,
						clamp: true,
					},
					legend: {
						display: false,
					},
				},
			}}
		/>
	);
};

export default HourlyStats;
