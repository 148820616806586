/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Chip,
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import routes from '../../api/routes';
import { MissionForPayrollDto } from '../../dto/mission';
import useFetch from '../../hooks/useFetch';
import { PageType } from '../../pages/humanRessources/payroll/GenericMissionPage';
import { useStyles } from '../missions/MissionsTable';

type MissionsTableProps = {
	userId?: string;
	periodStart?: string;
	periodEnd?: string;
	associationId?: number;
	clearPeriod?: () => any;
	missionType?: string;
	pageType?: PageType;
	setReValues?: (strs) => any;
	reValues?: string[];
	selectedRe?: string;
};

const GenericMissionsTable = ({
	periodStart,
	periodEnd,
	associationId,
	clearPeriod,
	userId,
	missionType,
	pageType,
	setReValues,
	reValues = [],
	selectedRe = null,
}: MissionsTableProps) => {
	const theme = useTheme();
	const isBigScreen = useMediaQuery(theme.breakpoints.up('md'));
	const [missionStatus, setMissionStatus] = useState(pageType === 'badge' ? 'future' : 'current');
	const status = periodStart || periodEnd ? 'custom' : missionStatus;

	let periodStartString = periodStart ? moment(periodStart).format('YYYY-MM-DD') : '';
	let periodEndString = periodEnd ? moment(periodEnd).format('YYYY-MM-DD') : '';

	const [renderedMissions, setRenderMissions] = useState([]);
	let [isLoading, missions] = useFetch<MissionForPayrollDto[]>(
		routes.missions.allForPayroll(
			{},
			{
				userId,
				associationId,
				status,
				periodStart: periodStartString,
				periodEnd: periodEndString,
				accounting: pageType === 'comptabilite',
			},
		),
		[associationId, status, periodEndString, periodEndString, userId],
	);

	if (missionType && missionType !== 'Tout sélectionner') {
		missions = missions?.filter((mission) => mission.missionType === missionType);
	}

	useEffect(() => {
		if (missions) {
			if (selectedRe === '' || selectedRe === 'Tous' || !selectedRe) {
				setRenderMissions(missions);
			} else {
				setRenderMissions(missions.filter((mission) => mission.teamManagerName === selectedRe));
			}
			const uniqueReValues = missions
				.map(({ teamManagerName }) => teamManagerName)
				.filter((value, index, self) => self.indexOf(value) === index)
				.sort((a, b) => a.localeCompare(b));
			setReValues(['', 'Tous', ...uniqueReValues]);
		}
	}, [missions]);

	useEffect(() => {
		if (missions) {
			if (selectedRe === '' || selectedRe === 'Tous') {
				setRenderMissions(missions);
			} else {
				setRenderMissions(missions.filter((mission) => mission.teamManagerName === selectedRe));
			}
		}
	}, [selectedRe]);

	const classes = useStyles({ border: false });
	const history = useHistory();

	const tabs = [
		{ status: 'current', label: 'En cours' },
		{ status: 'future', label: 'À venir' },
		{ status: 'past', label: 'Terminées' },
	];
	return (
		<>
			<Box mt={2} className={classes.tabs}>
				<ToggleButtonGroup
					style={{ display: 'flex', flexWrap: 'wrap' }}
					exclusive
					value={status}
					onChange={(e, v) => {
						if (v !== 'custom') {
							setMissionStatus(v);
							clearPeriod?.();
						}
					}}
				>
					{tabs.map(({ status, label }) => (
						<ToggleButton key={status} value={status}>
							<Box mr={1}>{label}</Box>
							{status === missionStatus && (
								<Chip size="small" color="primary" label={renderedMissions?.length ?? '?'} />
							)}
						</ToggleButton>
					))}
				</ToggleButtonGroup>
			</Box>
			<Box
				my={2}
				style={{
					overflow: 'auto',
					maxWidth: isBigScreen ? 'calc(100vw - 356px)' : 'calc(100vw - 20px)',
					border: '1px solid #CCC',
					borderRadius: '3px',
					borderTopLeftRadius: 0,
					marginTop: 0,
				}}
			>
				{isLoading ? (
					<Box display="flex" justifyContent="center" my={8}>
						<CircularProgress />
					</Box>
				) : (
					<Table size="medium" classes={{ root: classes.tableRoot }}>
						<TableHead>
							<TableRow>
								<TableCell>N°Trico</TableCell>
								<TableCell>RE</TableCell>
								{pageType !== 'comptabilite' ? (
									<TableCell>Rythme</TableCell>
								) : (
									<>
										<TableCell>Prix HT</TableCell>
										<TableCell>Montant TVA</TableCell>
										<TableCell>Prix TTC</TableCell>
									</>
								)}
								<TableCell>Début</TableCell>
								<TableCell>Fin</TableCell>
								<TableCell>Tricoteurs</TableCell>
								{pageType === 'payroll' && (
									<>
										<TableCell>FEE</TableCell>
										<TableCell>FES</TableCell>
										<TableCell>TR initiaux</TableCell>
										<TableCell>Primes parrainage</TableCell>
									</>
								)}
							</TableRow>
						</TableHead>
						<TableBody>
							{renderedMissions.length !== 0 &&
								renderedMissions?.map((m) => (
									<TableRow
										key={m.id}
										style={{ cursor: 'pointer' }}
										onClick={() => {
											let path;
											switch (pageType) {
												case 'badge':
													path = '/logistique/badges/';
													break;
												case 'payroll':
													path = '/paie/';
													break;
												case 'comptabilite':
													path = '/comptabilite/mission/';
													break;
												default:
													path = '/logistique/stocks-mission/';
													break;
											}
											history.push(path + m.id.toString());
										}}
									>
										<TableCell align="left">{m.tricoCode}</TableCell>
										<TableCell align="left">{m.teamManagerName}</TableCell>
										{pageType !== 'comptabilite' ? (
											<TableCell align="left">{m.rhythm.toUpperCase()}</TableCell>
										) : (
											<>
												<TableCell>{m.total_amountWithoutTva}</TableCell>
												<TableCell>{m.total_tva}</TableCell>
												<TableCell>{m.total_amount}</TableCell>
											</>
										)}
										<TableCell align="left">{moment(m.startDate).format('DD/MM/YYYY')}</TableCell>
										<TableCell align="left">{moment(m.endDate).format('DD/MM/YYYY')}</TableCell>
										<TableCell align="left">{m.tricoteurs}</TableCell>
										{pageType === 'payroll' && (
											<>
												<TableCell align="left">{m.totalFee}</TableCell>
												<TableCell align="left">{m.totalFes}</TableCell>
												<TableCell align="left">{m.initialTickets}</TableCell>
												<TableCell align="left">{m.sponsorshipsCount}</TableCell>
											</>
										)}
									</TableRow>
								))}
						</TableBody>
					</Table>
				)}
			</Box>
		</>
	);
};

export default GenericMissionsTable;
